export enum MetamapValidationStatus {
    inProgress,
    available,
    approved,
    rejected,
}

export enum HomeTransactionsFilter {
    all,
    payments,
    topUps,
}

export enum TransactionType {
    send,
    received,
    change,
    payIn,
    payOut,
}